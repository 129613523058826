import Vue from 'vue'
import { io } from 'socket.io-client'
import { getServer } from '@/globals'

export function closeError (id) {
  Vue.notify.close(id)
}

export function showInfo (text, options = {}) {
  Vue.notify({
    ignoreDuplicates: true,
    group: 'main',
    duration: 1000,
    text,
    type: 'success',
    ...options
  })
}

export function showError (text, options = {}) {
  Vue.notify({
    ignoreDuplicates: true,
    group: 'main',
    duration: 3000,
    text,
    type: 'error',
    ...options
  })
}
