<template>
  <div class="container">
    <div class="bg"/>
    <div class="login-container">
      <div class="login-centered">
        <Card title="Autenticação">
          <div v-if="google_name" class="py-5 text-center">
            <p>
              Autenticando {{ google_name }} utilizando Google
            </p>
            <p>
              <span class="btn-link" @click="firebaseLogout">Clique aqui para usar outra conta.</span>
            </p>
          </div>
          <template v-else>
            <div id="firebaseui-auth-container"></div>
            <hr>
            <p class="text-muted text-center">Utilizar usuário e senha fornecido pelo administrador</p>
            <div class="form-group username">
              <label>Nome de usuário</label>
              <input
                v-model="form.username"
                autocomplete="email"
                autofocus
                class="form-control"
                name="email"
                placeholder="E-mail"
                required
                type="email"
              />
            </div>

            <div class="form-group password">
              <label>Senha</label>
              <input
                v-model="form.password"
                autocomplete="current-password"
                class="form-control"
                name="password"
                placeholder="Senha"
                required
                type="password"
              />
            </div>
          </template>
          <div class="text-center">
            <CoreButton
              :loading="loading"
              loading-text="Aguarde ..."
              :disabled="!valid"
              v-on:click="login()">Entrar
            </CoreButton>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import { H } from 'hennig-common'
import Card from '@/components/Card'
import CoreButton from '@/components/CoreButton'
import { firebaseInit, firebaseLogout } from '@/firebase'
import { setLoggedToken } from '@/common'

export default {
  name: 'Login',
  components: {
    CoreButton,
    Card
  },
  data () {
    return {
      loading: false,
      google_name: '',
      form: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    valid () {
      return Object.values(this.form).every(v => !!v) && !this.loading
    }
  },
  mounted () {
    this.firebaseInit()
  },
  methods: {
    goHome () {
      if (this.$route.params.nextUrl) {
        this.$router.replace(this.$route.params.nextUrl)
      } else {
        this.$router.replace({ name: 'Home' })
      }
    },
    firebaseInit () {
      // FirebaseUI config.
      firebaseInit(
        {
          callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
              if (authResult) {
                this.goHome()
              }

              return false
            }
          }
        }, {
          onUser: (user) => {
            this.google_name = (user && user.name) || ''
          },
          backendCall: (token) => {
            this.login(token)
          }
        })
    },
    async firebaseLogout () {
      firebaseLogout()
      this.google_name = ''
      await this.$nextTick()
      this.firebaseInit()
    },
    login (params = this.form) {
      this.loading = true
      H.rpc('Auth', 'login', [params], r => {
        this.loading = false
        if (r) {
          setLoggedToken(r)
          this.goHome()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .login-centered {
    width: 500px;
    max-width: 100%;
  }
}
</style>
