<template>
  <div class="container">
    <div class="card-deck">
      <router-link
        v-if="isAdmin"
        class="card"
        to="/whitelabels"
      >
        <div class="card-body">
          <b class="card-title">
            <i class="la la-users"/>
            Clientes
          </b>
          <p class="card-text">Gerencie os WhiteLabels</p>
        </div>
      </router-link>

      <router-link class="card" to="/users">
        <div class="card-body">
          <b class="card-title"><i class="la la-user"></i> Usuários</b>
          <div class="card-text">
            Gerencie os usuários, emails, acessos e permissões
          </div>
        </div>
      </router-link>

      <router-link class="card" :to="{name: 'EmailLog'}">
        <div class="card-body">
          <i class="la la-file-medical-alt"></i>
          <span>Registros de envio de e-mail</span>
        </div>
      </router-link>
      <router-link class="card" to="/activity/log">
        <div class="card-body">
          <i class="la la-heartbeat"></i>
          <span>Registros de atividade</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { isAdmin, isManager } from '@/globals'
import { useWhiteLabel } from '@/stores/whitelabel'
import { mapState } from 'pinia'

export default {
  name: 'Home',
  computed: {
    ...mapState(useWhiteLabel, ['whitelabel']),
    isAdmin,
    isManager
  }
}
</script>

<style lang="scss" scoped>
a {
  display: block;
  margin-bottom: .5em;
}
</style>
