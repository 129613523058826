<template>
  <nav class="navbar navbar-expand-md shadow-sm">
    <div class="container">
      <router-link class="navbar-brand d-flex flex-column align-items-center" to="/">
        <img class="brand-logo" :src="whitelabel.brand_logo" alt=""/>
      </router-link>

      <router-link class="navbar-brand d-flex flex-column align-items-center" to="/">
        {{ whitelabel.name }}
      </router-link>

      <button class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
        <i class="la la-2x la-bars"></i>
      </button>

      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <!-- Left Side Of Navbar -->
        <ul class="navbar-nav mr-auto flex-sm-row justify-content-sm-center">
          <router-link class="nav-link" :to="{name:'Home'}">Home</router-link>
        </ul>

        <!-- Right Side Of Navbar -->
        <ul class="navbar-nav ml-auto flex-sm-row justify-content-sm-center">
          <li class="nav-item dropdown">
            <a id="navbarDropdown2" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button">
              {{ auth.name }}
            </a>

            <div aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-right">
              <router-link class="dropdown-item" to="/profile">Meus dados</router-link>
              <div class="dropdown-divider"></div>
              <div class="dropdown-item" @click="logout()">Sair</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Confirmation
      ref="Confirmation"
      text="Deseja realmente sair?"
    />
  </nav>
</template>

<script>
import moment from 'moment'
import { showInfo } from '@/notifications'
import Confirmation from '../dialogs/Confirmation'
import { auth, gates, isAdmin, isManager } from '@/globals'
import { removeLoggedToken } from '@/common'
import { mapState } from 'pinia'
import { useWhiteLabel } from '@/stores/whitelabel'
import { firebaseLogout } from '@/firebase'
import { useFilter } from '@/stores/filter'

export default {
  name: 'Logged',
  components: { Confirmation },
  data () {
    return {
      auth
    }
  },
  computed: {
    ...mapState(useWhiteLabel, ['whitelabel']),
    ...mapState(useFilter, ['event_name']),
    isAdmin,
    isManager,
    gates: () => gates
  },
  methods: {
    format (v) {
      return moment(v).fromNow()
    },
    showNotification () {
      if (!this.notifications.length) {
        showInfo('Nenhum novo aviso')
      }
    },
    logout () {
      this.dialogs().Confirmation.show({}, r => {
        if (r) {
          removeLoggedToken()
          firebaseLogout()
          location.href = '/'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.navbar {
  padding: 0;
  min-height: 50px;
  background-color: #fff;

  img.brand-logo {
    max-height: 50px;
  }
}

</style>
